import * as React from 'react';
import { useSnackbar } from 'notistack';
import { toArray, downloadUri } from '../utils/Utils';
import { HoursTimespan } from '../components/HoursTimespanSelector';
import { dateToISOWithReducedPrecision } from '../utils/DateUtils';
import moment from 'moment';
import { useFetchApi } from '../utils/UseFetchApi';
import { IErrorDetails, IWarningDetails } from 'realityservices-ui-components';
import { Link } from 'react-router-dom';

export enum JobState {
    New = "New",
    Queued = "Queued",
    Active = "Active",
    Completed = "Completed"
}

export enum JobOutcome {
    Success = "success",
    Failed = "failed",
    Cancelled = "cancelled",
}

export interface IInputInformation {
    photoCount?: number;
    gigaPixelsAT?: number;
    gigaPixelsRecons?: number;
    pointCloudCount?: number;
    megaPoints?: number;
    megaPointsRecons?: number;
    calibrationFromCache?: boolean;
    reconstructionFromCache?: boolean;
}

export interface IExecutionInformation {
    outcome?: JobOutcome;
    startTime?: string;
    endTime?: string;
    computeTime?: number;
    exitCode?: number;
    inputInformation?: IInputInformation;
    estimatedUnits?: number;
    errors?: IErrorDetails[];
    warnings?: IWarningDetails[];
    failedJobStep?: string;
}

export interface ISubmissionDetails {
    time?: string;
    userAgent?: string;
    clusterId?: string;
    clusterVersion?: string;
    fileShareAccount?: string;
}

export interface ICacheSettings {
    createCache: boolean;
    useCache?: string;
}

export interface IJobSettings {
    meshQuality?: string;
    outputs?: IOutputDetails[];
    processingEngines?: number;
    cacheSettings?: ICacheSettings;
}

export interface IOutputDetails {
    id?: string;
    format?: string;
    visibility?: string;
}

export interface IInputDetails {
    id?: string;
    type?: string;
    description?: string;
}

export interface IUserDetails {
    id: string;
    email: string;
}
export interface IDataCenter {
    location: string;
    id: string;
}
export interface IJob {
    id: string;
    projectId: string;
    name: string;
    state: JobState;
    connectProjectId: string;
    type: string;
    ultimateSite: string;
    executionInformation?: IExecutionInformation;
    submissionDetails?: ISubmissionDetails;
    inputs?: IInputDetails[];
    settings?: IJobSettings;
    userDetails: IUserDetails;
    creationTime: string;
    dataCenter: IDataCenter;
}

export interface ITimeSpan {
    startDate?: Date;
    endDate?: Date;
}

//link to job details page
export function JobLink(props: { id: string; name?: string }) {
    return (
        <Link
            to={{ pathname: `/admin/jobs/${props.id}` }}
            style={{ textDecoration: "none" }}
        >
            {props.name ?? props.id}
        </Link>
    );
}

export function isJobActiveOrQueued(job: IJob) {
    return job.state === JobState.Active || job.state === JobState.Queued;
}

export function getJobSize(job: IJob): string {
    if (!job.executionInformation?.inputInformation) return "-";
    const inputInfo = job.executionInformation?.inputInformation;
    const size = (inputInfo.gigaPixelsAT ?? 0) + (inputInfo.megaPoints ?? 0);
    return (Math.round(size * 100) / 100).toString();
}

export function useJobReport(queryParams?: string, ignoreTimeSpan: boolean = false) {
    const sevenDaysMilliseconds = 7 * 24 * 60 * 60 * 1000;
    const [hoursTimeSpan, setHoursTimeSpan] = React.useState<HoursTimespan>(HoursTimespan.LastWeek);
    const [timespan, setTimespan] = React.useState<ITimeSpan>({
        startDate: ignoreTimeSpan ? undefined : new Date(Date.now() - sevenDaysMilliseconds),
        endDate: undefined
    });
    const fetchApi = useFetchApi<IJob[]>(getUrl(timespan.startDate, timespan.endDate));

    function getUrl(startDate?: Date, endDate?: Date) {
        const url = new URL("/api/v2/jobs/report", window.location.origin);

        if (queryParams) url.search = queryParams;
        if (startDate) url.searchParams.set('startTime', dateToISOWithReducedPrecision(startDate));
        if (endDate) url.searchParams.set('endTime', dateToISOWithReducedPrecision(endDate));

        return url.href;
    }
    
    function refresh() {
        fetchApi.run();
    }

    function handleTimespanChange(newSpan: ITimeSpan) {
        setTimespan(newSpan);
        fetchApi.run(getUrl(newSpan.startDate, newSpan.endDate));
    }
    
    function handleHoursTimespanChange(newSpan: HoursTimespan) {
        var startDate: Date = new Date(moment().subtract(newSpan as number, 'hours').toDate());
        setHoursTimeSpan(newSpan);
        setTimespan({ startDate });
        fetchApi.run(getUrl(startDate));
    }

    // run once at startup
    React.useEffect(() => {
        fetchApi.run();
    }, []);

    return {
        data: fetchApi.data,
        hasData: fetchApi.hasData,
        refresh,
        isFetching: fetchApi.isFetching,
        fetchCount: fetchApi.fetchCount,
        timespan,
        SetTimespan: handleTimespanChange,
        hoursTimeSpan,
        SetHoursTimespan: handleHoursTimespanChange,
        error: fetchApi.error
    }
}

export function useJobActions() {
    const { enqueueSnackbar } = useSnackbar();
    const terminateApi = useFetchApi<IJob>();
    const downloadApi = useFetchApi<any>();

    function terminate(maybeJobs: IJob[] | IJob) {
        toArray(maybeJobs).forEach(job => {
            var result = window.confirm(`Are you sure you want to kill '${job.name}' id: ${job.id} that belong to ${job.userDetails.email} ?"`);
            if (!result)
                return;

            enqueueSnackbar(`Killing '${job.name}' id: ${job.id}...`, { variant: "info" });

            terminateApi.run(window.location.origin + "/api/v2/jobs/" + job.id + "/terminate",
                {
                    method: 'POST'
                })
                .then(result =>
                    enqueueSnackbar(`Job '${result?.name}' killed!`, { variant: "success" }))
                .catch(error => {
                    enqueueSnackbar(`Failed to kill '${job.name}' : ${error.toString()}`, { variant: "error" });
                });
        });
    }

    function downloadLogs(maybeJobs: IJob[] | IJob) {
        toArray(maybeJobs).forEach(job => {
            downloadApi.run(window.location.origin + "/api/v2/jobs/" + job.id + "/logsaccess")
                .then(result => {
                    if (!result?.uri) {
                        enqueueSnackbar(`Failed to download logs of '${job.name}'`);
                        return;
                    }
                    downloadUri(result.uri);
                })
                .catch(error => {
                    enqueueSnackbar(`Failed to download '${job.name}' : ${error.toString()}`, { variant: "error" });
                })
        });
    }

    return {
        terminate,
        downloadLogs
    }
}
