import * as React from 'react';
import MaterialTable, { Action, Column } from "@material-table/core";
import HoursTimespanSelector, { HoursTimespan } from './HoursTimespanSelector';
import {
    getJobSize,
    IJob,
    JobLink,
    useJobActions,
    useJobReport,
} from "../Model/Job";
import { createDateColumn } from '../utils/DateUtils';
import { JobLogsDialog, JobLogMessageProperty, IErrorDetails }  from "realityservices-ui-components";
import { EmailEllipsis, hasAdminOrViewOnlyAccess } from '../utils/Utils';
import { useAuthorizationContext } from '../utils/AuthorizationContext';

interface IFilterProps {
    jobFilter?: string;
    toggleTabRefresh: boolean;
}

export default function CompletedJobsFilter(props: IFilterProps) {
    const jobFilter = props.jobFilter ? props.jobFilter : 'state=Completed';
    const jobReport = useJobReport(jobFilter);
    const jobActions = useJobActions();
    const [isJobLogsModalOpen, setIsJobLogsModalOpen] = React.useState(false);
    const [selectedJob, setSelectedJob] = React.useState<IJob>();
    const isFailedJobsFilter = props.jobFilter === 'outcome=Failed';
    const frontendAccessLevel = useAuthorizationContext();
    const isAdminOrViewOnlyUser = hasAdminOrViewOnlyAccess(frontendAccessLevel);

    function onTimespanChanged(newTimespan: HoursTimespan) {
        jobReport.SetHoursTimespan(newTimespan);
    }

    function getActions() {
        const actions: Action<IJob>[] = [
            {
                icon: "refresh",
                tooltip: "Refresh",
                isFreeAction: true,
                onClick: () => jobReport.refresh()
            },
        ];

        if (isAdminOrViewOnlyUser) {
            if (isFailedJobsFilter) {
                actions.push({
                    tooltip: "View logs",
                    icon: "find_in_page",
                    onClick: (event: any, rowData: IJob | IJob[]) => {
                        setIsJobLogsModalOpen(true);
                        setSelectedJob(rowData as IJob);
                    },
                });
            }

            actions.push({
                tooltip: "Download worker app logs",
                icon: "get_app",
                onClick: (event: any, rowData: any) =>
                    jobActions.downloadLogs(rowData),
            });
        }
        return actions;
    }

    function getColumns(): Column<IJob>[] {
        const customFilterAndSearch = (
            term: string,
            rowData: IJob,
            propertyToCheck: JobLogMessageProperty
        ) => {
            const errors = rowData.executionInformation?.errors ?? [];

            return (
                errors.some((error) =>
                    error.messages?.some((message) =>
                        message[propertyToCheck]
                            ?.toLowerCase()
                            .includes(term.toLowerCase())
                    )
                ) ?? false
            );
        };

        const getDistinctValues = (
            errors: IErrorDetails[],
            property: JobLogMessageProperty
        ) => {
            return Array.from(
                new Set(
                    errors?.flatMap((error) =>
                        error.messages?.map((message) => message[property])
                    ) || []
                )
            );
        };

        const renderDistinctValues = (
            rowData: IJob,
            property: JobLogMessageProperty
        ): JSX.Element => {
            const errors = rowData.executionInformation?.errors;

            const values = errors ? getDistinctValues(errors, property) : [];

            const valuesString =
                values.length <= 2
                    ? values.join(", ")
                    : `${values.slice(0, 2).join(", ")}, + ${
                          values.length - 2
                      }`;

            return <>{valuesString}</>;
        };

        const customSort = (
            firstJob: IJob,
            secondJob: IJob,
            property: JobLogMessageProperty
        ) => {
            const firstJobErrors = firstJob.executionInformation?.errors ?? [];
            const secondJobErrors = secondJob.executionInformation?.errors ?? [];

            const firstJobValue = firstJobErrors
                ? getDistinctValues(firstJobErrors, property).join(", ")
                : "";
            const secondJobValue = secondJobErrors
                ? getDistinctValues(secondJobErrors, property).join(", ")
                : "";

            return firstJobValue.localeCompare(secondJobValue);
        };

        const columns: Column<IJob>[] = [
            {
                title: "Name",
                field: "name",
                cellStyle: {
                    maxWidth: "150px",
                    wordWrap: "break-word"
                },
                headerStyle: { maxWidth: "150px" },
                render: (rowData: IJob) => <JobLink id={rowData.id} name={rowData.name} />,
            },
            { title: "Type", field: "type" },
            {
                title: "Email",
                field: "userDetails.email" as any,
                cellStyle: { maxWidth: "150px" },
                headerStyle: { maxWidth: "150px" },
                render: (rowData: IJob) => (
                    <EmailEllipsis email={rowData.userDetails.email} />
                ),
            },
            createDateColumn("Start", "executionInformation.startTime"),
            createDateColumn("End", "executionInformation.endTime", "desc"),
        ];

        if (isFailedJobsFilter && isAdminOrViewOnlyUser) {
            columns.push(
                {
                    title: "Error code",
                    field: "executionInformation.errors",
                    render: (rowData: IJob) => {
                        return renderDistinctValues(
                            rowData,
                            JobLogMessageProperty.Code
                        );
                    },
                    customFilterAndSearch: (term, rowData) => {
                        return customFilterAndSearch(
                            term,
                            rowData as IJob,
                            JobLogMessageProperty.Code
                        );
                    },
                    customSort: (firstJob, secondJob) => customSort(firstJob, secondJob, JobLogMessageProperty.Code),
                    cellStyle: {
                        maxWidth: "250px",
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                    },
                },
                {
                    title: "Error source app",
                    field: "executionInformation.errors",
                    render: (rowData: IJob) => {
                        return renderDistinctValues(
                            rowData,
                            JobLogMessageProperty.Source
                        );
                    },
                    customFilterAndSearch: (term, rowData) => {
                        return customFilterAndSearch(
                            term,
                            rowData as IJob,
                            JobLogMessageProperty.Source
                        );
                    },
                    customSort: (firstJob, secondJob) => customSort(firstJob, secondJob, JobLogMessageProperty.Source),
                    cellStyle: { maxWidth: "200px" },
                },
                {
                    title: "Error caused by",
                    field: "executionInformation.errors",
                    render: (rowData: IJob) => {
                        return renderDistinctValues(
                            rowData,
                            JobLogMessageProperty.Category
                        );
                    },
                    customFilterAndSearch: (term, rowData) => {
                        return customFilterAndSearch(
                            term,
                            rowData as IJob,
                            JobLogMessageProperty.Category
                        );
                    },
                    customSort: (firstJob, secondJob) => customSort(firstJob, secondJob, JobLogMessageProperty.Category),
                    cellStyle: { maxWidth: "150px" },
                },
                {
                    title: "Failure step",
                    field: "executionInformation.failedJobStep",
                }
            );
        }
        columns.push(
            {
                title: "Location",
                field: "dataCenter.location",
            },
            {
                title: "Size",
                field: "executionInformation.inputInformation",
                render: (rowData) => getJobSize(rowData),
                sorting: true,
                filtering: false,
                editable: "never",
            }
        );

        return columns;
    }

    function closeJobLogsModal() {
        setIsJobLogsModalOpen(false);
    }

    return (
        <div>
            <MaterialTable<IJob>
                title={
                    <HoursTimespanSelector
                        default={jobReport.hoursTimeSpan}
                        onValueChanged={onTimespanChanged}
                    />
                }
                isLoading={jobReport.fetchCount === 0} // Avoid flicker on auto refresh and show loading only on first attempt
                columns={getColumns()}
                data={jobReport.data ?? []}
                actions={getActions()}
                options={{
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30, 40, 50],
                    search: true,
                    headerStyle: {
                        backgroundColor: jobReport.error
                            ? "#FF0000"
                            : "#9BA5AE",
                        color: "#000000",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    },
                    padding: "dense",
                    maxBodyHeight: "63vh",
                    rowStyle: {
                        fontSize: "0.875rem",
                    },
                }}
            />
            {isJobLogsModalOpen && selectedJob && isAdminOrViewOnlyUser &&(
                <JobLogsDialog
                    title={`Job logs: ${selectedJob.name}` }
                    errors={selectedJob.executionInformation?.errors}
                    warnings={selectedJob.executionInformation?.warnings }
                    isOpen={isJobLogsModalOpen}
                    onClose={closeJobLogsModal}
                />
            )}
        </div>
    );

}


